<template>
	<div>
		<h2>{{ $t('gynecologie.season_information') }}</h2>
		<div class="box">
			 <form @submit.prevent="checkForm">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="season_label" class="col-form-label">{{ $t("gynecologie.form.nom") }} *</label>
                            <input type="text" id="season_label" class="form-control" v-model="form.season_label" :class="{ 'is-invalid': errors && errors.indexOf('Label') > -1 }">
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <label for="season_start" class="col-form-label">{{ $t("gynecologie.form.date_debut") }} *</label>
                            <e-datepicker id="season_start" v-model="form.season_start" :class="{ 'is-invalid': errors && errors.indexOf('DateDebut') > -1 }"></e-datepicker>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <label for="season_end" class="col-form-label">{{ $t("gynecologie.form.date_fin") }} *</label>
                            <e-datepicker id="season_end" v-model="form.season_end" :class="{ 'is-invalid': errors && errors.indexOf('DateFin') > -1 }"></e-datepicker>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <div class="vue-custom-control-inline">
                                <b-form-checkbox id="season_default" v-model="form.season_default"> <label for="season_default">{{ $t("gynecologie.form.saison_defaut") }}</label></b-form-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <div class="vue-custom-control-inline">
                                <b-form-checkbox id="season_use_global_number_template" v-model="form.season_use_global_number_template"> <label for="season_use_global_number_template">{{ $t("gynecologie.form.season_use_global_number_template") }}</label></b-form-checkbox>
                            </div>
                        </div>

                        <template v-if="form.season_use_global_number_template">
                            <div class="form-group">
                                <label class="col-form-label">{{ $t("monte.numero_contrat") }}</label>
                                <div class="form-inline">
                                    <input
                                        class="form-control col-2"
                                        v-model="form.season_prefix.numbertemplate_prefix"
                                        required
                                    />
                                    <b-input-group class="col-5">
                                        <b-input-group-prepend id="popover-target-format-info">
                                            <span class="input-group-text">
                                                <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                            </span>
                                        </b-input-group-prepend>
                                        <b-form-input
                                            v-model="form.season_prefix.numbertemplate_pattern"
                                            required
                                        ></b-form-input>
                                        <b-popover target="popover-target-format-info" triggers="hover" placement="top">
                                            <template v-slot:title>{{ $t('monte.format_numero_contrat') }}</template>
                                            <p
                                                v-for="(val, key) in date_formatter"
                                                :key="key"
                                            >
                                                <b>{{ key }}</b> - {{ val.label }} - {{ val.value }}
                                            </p>
                                            
                                        </b-popover>
                                    </b-input-group>
                                    <span disabled class="form-control col-5">
                                        {{ formatted_contract_num }}
                                    </span>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
	             <div class="text-center">
	                <b-button pill variant="primary" class="mt-3"  @click.prevent="checkForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.modifier") }}</b-button>
	            </div>
            </form>
		</div>
	</div>
</template>

<script type="text/javascript">
    import GynecologieMixin from "@/mixins/Gynecologie.js"

	export default {
		name: 'Information',
		mixins: [GynecologieMixin],
		props: ['season'],
		data () {
			return {
                processing: false,
                form: {
                    season_label: "",
                    season_start: null,
                    season_end: null,
                    season_default: true,
                    season_use_global_number_template: false,
                    season_prefix: {
						numbertemplate_prefix: '',
						numbertemplate_pattern: '9999'
					},
                },
                date_formatter: {
                    '9': {
                        label: this.$t('compta.form.format_9'),
                        value: 0
                    },
                },
                errors: []
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.form.season_label = this.season.season_label
				this.form.season_start = this.season.season_start
				this.form.season_end = this.season.season_end
				this.form.season_default = this.season.season_default
				this.form.season_use_global_number_template = this.season.season_use_global_number_template

                if(this.season.number_template.length > 0)
                {

                    this.form.season_prefix = {
                        numbertemplate_prefix: this.season.number_template[0].numbertemplate_prefix,
                        numbertemplate_pattern: this.season.number_template[0].numbertemplate_pattern
                    }
                }
			},

            async checkForm () {
                this.processing = true
                this.errors = []

                if(!this.form.season_label) {
                    this.errors.push("Label")
                }

                if(!this.form.season_start) {
                    this.errors.push("DateDebut")
                }

                if(!this.form.season_end) {
                    this.errors.push("DateFin")
                }

                if(this.errors.length > 0) {
                    return false
                } else {
                    let result = null
                    if(this.form.season_default) {
                        await this.editAllSeasonDefault(
                            {season_default: false}
                        )
                    }

                    if(this.season.season_id) {
                        result = await this.editSeason(
                            this.season.season_id,
                            {
                                season_label: this.form.season_label,
                                season_start: this.form.season_start,
                                season_end: this.form.season_end,
                                season_default: this.form.season_default,
                                season_use_global_number_template: this.form.season_use_global_number_template,
                                numbertemplate_prefix: this.form.season_prefix.numbertemplate_prefix,
                                numbertemplate_pattern: this.form.season_prefix.numbertemplate_pattern,
                            }
                        );
                    } else {
                        result = await this.addSeason(
                            this.form.season_label,
                            this.form.season_start,
                            this.form.season_end,
                            this.form.season_default,
                            this.form.season_use_global_number_template,
                            this.form.season_prefix.numbertemplate_prefix,
                            this.form.season_prefix.numbertemplate_pattern,
                        );
                    }

                    this.processing = false

                    if(result) {
                    	this.$emit('SaisonFiche::reload_season', this.form.season_label)
                        this.successToast("toast.info_modif_succes")
                    } else {
                        this.failureToast();
                    }
                }
            }
		},
        computed: {
            formatted_contract_num () {
			    let num = this.form.season_prefix.numbertemplate_pattern

			    Object.keys(this.date_formatter).forEach(identifier => {
			        num = num.replace(new RegExp(identifier, 'g'), this.date_formatter[identifier].value)
			    })

			    return this.form.season_prefix.numbertemplate_prefix + num
			},
        },
		components: {
            SeasonAjout : () => import('@/components/Gynecologie/SeasonAjout'),
		}
	}

</script>